import { Box, Typography } from "@mui/material";

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

// Iconos 
import supermarket from "../assets//location/shopping-cart.png";
import drugStore from "../assets/location/pharmacy.png";
import cinema from "../assets/location/film.png";
import hospital from "../assets/location/hospital.png";
import bank from "../assets/location/bank-building.png";
import casino from "../assets/location/spade-token.png";
import kfc from "../assets/location/KFC.png";
import burger from "../assets/location/burger-king.png";
import sushi from "../assets/location/sushi.png";
import seafood from "../assets/location/fish-and-lemon-slice.png";
import restaurants from "../assets/location/cutlery.png";

import "../Location/Location.css"
export default function Location({languaje}) {
  const location = {
    nearPlaces:[
        {icono: supermarket, nombre: languaje.service1},
        {icono: drugStore, nombre: languaje.service2},
        {icono: cinema, nombre: languaje.service3},
        {icono: hospital, nombre: languaje.service4},
        {icono: bank, nombre: languaje.service5},
        {icono: casino, nombre: languaje.service6},
        {icono: kfc, nombre: languaje.service7},
        {icono: burger, nombre: languaje.service8},
        {icono: sushi, nombre: languaje.service9},
        {icono: seafood, nombre: languaje.service10},
        {icono: restaurants, nombre: languaje.service11},
        ,
    ]
  };

  return (

    <Box display={"grid"} gridTemplateColumns={"1fr"} alignItems={"center"} justifyItems={"center"} sx={{gridColumnStart:2, gridColumnEnd:3}}>
        <Typography fontSize={"1.5rem"} fontFamily={"Century Gothic"} mb={"15px"} mt={"1rem"} color={"#5e5e5e"} textAlign={"center"}>
          {languaje.tittle}
        </Typography>
        <Typography
          fontSize={"1rem"}
          mb={"45px"}
          textAlign={"left"}
          color={"#5e5e5e"}
          width={"85%"}
          justifySelf={"center"}
        >
          {languaje.text1}
        </Typography>
        <Box display={"flex"} justifyContent={"center"} width={"100%"}>
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3733.1700408413603!2d-103.4218299894242!3d20.662659980816972!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8428ae9d0104de95%3A0xef288f084e72a108!2sEuropean%20Life%20Style%20Executive%20Suites!5e0!3m2!1sen!2smx!4v1708303420051!5m2!1sen!2smx"
          style={{ border: 0, marginBottom: "35px" }}
          width="95%"
          height="400"
          allowfullscreen=""
          loading="lazy"
          referrerpolicy="no-referrer-when-downgrade"
          justifySelf="center"
        ></iframe>
        </Box>
        <Typography fontSize={"1.5rem"} fontFamily={"Century Gothic"} mb={"15px"} color={"#5e5e5e"} textAlign={"center"} >
          {languaje.tittle2}
        </Typography>
        <TableContainer className="locationPageTable" component={Paper}>
          <Table sx={{ width:"95%"}} aria-label="avenidas principales">
            <TableBody>
              {languaje.mainAvenues.map((row) => (
                <TableRow
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell sx={{ fontSize:"1rem", color:"#5e5e5e"}} component="th" scope="row">{row.name}</TableCell>
                  <TableCell sx={{ fontSize:"1rem", color:"#5e5e5e"}} align="left">{row.time}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <Typography fontSize={"1.5rem"} fontFamily={"Century Gothic"} mt={"45px"} color={"#5e5e5e"} textAlign={"center"}>
          {languaje.tittle3}
        </Typography>
        
        <Box className="nearPlaces" >
                
                {location.nearPlaces.map((place, index) => (
                    <Box className="nearPlace" display={"flex"} mb={"20px"} alignItems={"center"}>
                    <img className="locationimg" src={place.icono} key={index} />
                    <Typography variant="body1" color="#5e5e5e" pl={1}>{place.nombre}</Typography>
                    </Box>             
                ))
                }
        </Box>
        <Typography fontSize={"1.5rem"} fontFamily={"Century Gothic"} my={"50px"} color={"#5e5e5e"} textAlign={"center"}>
          {languaje.tittle4}
        </Typography>
        <TableContainer className="locationPageTable" component={Paper}>
          <Table aria-label="avenidas principales">
            <TableHead>
              <TableRow>
                <TableCell sx={{ fontSize: "1.2rem", color: "#333", fontWeight: 'bold' }}>{languaje.placesofinteresttittle}</TableCell>
                <TableCell sx={{ fontSize: "1.2rem", color: "#333", fontWeight: 'bold' }} align="left">{languaje.placesofinteresttittle2}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {languaje.placesofinterest.map((row)  => (
             
                <TableRow
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell sx={{fontSize:"1rem", color:"#5e5e5e"}} component="th" scope="row">{row.name}</TableCell>
                  <TableCell sx={{fontSize:"1rem", color:"#5e5e5e"}} align="left">{row.time}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

      </Box>

  );
}
