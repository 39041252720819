import pool from "../assets/amenities/Alberca.webp"
import library from "../assets/amenities/Biblioteca.webp"
import bussinessCenter from "../assets/amenities/Business center.webp"
import lockBox from "../assets/amenities/Caja de seguridad.webp"
import parking from "../assets/amenities/Estacionamiento.webp"
import gym from "../assets/amenities/Gym.webp"
import lobby from "../assets/amenities/Lobby.webp"
import lounge from "../assets/amenities/Lounge.webp"
import reception from "../assets/amenities/Recepcion 24h.webp"
import boardroom from "../assets/amenities/Sala de Juntas.webp"
import terrace from "../assets/amenities/Terrazas.webp"


const AmenitiesMedia = {
    images:[
        {"original":parking,
        "thumbnail":parking},
        {"original":pool,
        "thumbnail":pool},
        {"original":gym,
        "thumbnail":gym},
        {"original":reception,
        "thumbnail":reception},
        {"original":bussinessCenter,
        "thumbnail":bussinessCenter},
        {"original":terrace,
        "thumbnail":terrace},
        {"original":lounge,
        "thumbnail":lounge},
        {"original":library,
        "thumbnail":library},
        {"original":boardroom,
        "thumbnail":boardroom},
        {"original":lobby,
        "thumbnail":lobby},
        {"original":lockBox,
        "thumbnail":lockBox},
    ]
}

export default AmenitiesMedia;