//Suites 3 recamaras

// Premium Queen Suite
import image1 from "../../assets/Suites/Suites 3 Recamaras/QueenSuite/QueenSuite-1.webp"
import image2 from "../../assets/Suites/Suites 3 Recamaras/QueenSuite/QueenSuite-2.webp"
import image3 from "../../assets/Suites/Suites 3 Recamaras/QueenSuite/QueenSuite-3.webp"
import image4 from "../../assets/Suites/Suites 3 Recamaras/QueenSuite/QueenSuite-4.webp"
import image5 from "../../assets/Suites/Suites 3 Recamaras/QueenSuite/QueenSuite-5.webp"
import image7 from "../../assets/Suites/Suites 3 Recamaras/QueenSuite/QueenSuite-6.webp"

const QueenSuite = {
    "images":[
        {
        "original":image1,
        "thumbnail":image1
        },
        {
        "original":image2,
        "thumbnail":image2
        },
        {
        "original":image3,
        "thumbnail":image3
        },
        {
        "original":image4,
        "thumbnail":image4
        },
        {
        "original":image5,
        "thumbnail":image5
        },
        {
        "original":image7,
        "thumbnail":image7
        }
    
    ] 
}

export default QueenSuite;