import React, { useContext } from "react";
import LangContext from "../langContext/langContext"; // Ajusta la ruta según tu estructura de proyecto

import langEn from "../lang/en-US.json";
import langEs from "../lang/es-MX.json";

import iconES from "../assets/NavBar/flag ES.webp";
import iconUS from "../assets/NavBar/flag US.webp";

import "./LanguageSwitcher.css"
import { Box, Typography } from "@mui/material";

function LanguageSwitcher() {
  const { setLanguage } = useContext(LangContext);

  const switchToEnglish = () => setLanguage(langEn);
  const switchToSpanish = () => setLanguage(langEs);

  return (
    <div className="LangContainer">
        <Box className="flex" onClick={switchToEnglish} >
          <img className="LangSwitcherbtn" src={iconUS} alt="US flag"  />
          <Typography fontSize={".9rem"} color={"white"} fontFamily={"Century Gothic"}>EN</Typography>
        </Box>
        <Box className="flex" onClick={switchToSpanish} >
          <img className="LangSwitcherbtn" src={iconES} alt="ES flag"  />
          <Typography fontSize={".9rem"} color={"white"} fontFamily={"Century Gothic"}>ES</Typography>
        </Box>
    </div>
  );
}

export default LanguageSwitcher;