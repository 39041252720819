import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import './index.css';
import './reset.css';


import { LangProvider } from './langContext/langContext';


const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <LangProvider>
    <App />
  </LangProvider>
);


