/*
/Componente principal
/aqui se encuentra importada la libreria React Router Dom y desde aqui se van a gestionar las rutas para navegar en las 
/distintas secciones de la pagina web.
*/
import React, { useContext } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Box } from "@mui/material";

import LangContext from "./langContext/langContext";

// Importación de componentes para pagina

import NavBar from "./NavBar/NavBar";
import WhatsIcon from "./WhatsIcon";
import Footer from "./Footer/Footer";

import PaginaPrincipal from "./Componentes pag principal/Pagina principal";
import SuitesView from "./Componentes suites/SuitesView";
import FullInfoSuitesView from "./Componentes full info suites/FullInfoSuitesView";

import Location from "./Location/Location";
import Amenities from "./Amenities/Amenities";

//Fotografias de todas las habitaciones
import LoftSuiteMedia from "./media/1 Room/LoftSuite";
import JuniorSuiteMedia from "./media/1 Room/JuniorSuite";
import PremiumJuniorSuiteMedia from "./media/1 Room/PremiumJuniorSuite";
import PremiumJuniorDoubleSuiteMedia from "./media/1 Room/PremiumJuniorDoubleSuite"
import {DeluxeJuniorSuiteMedia, DeluxeJuniorSuiteview } from "./media/1 Room/DeluxeJuniorSuite";

import MasterSuite from "./media/2 Rooms/MasterSuite";
import PremiumMasterSuite from "./media/2 Rooms/PremiumMasterSuite";
import MasterExecutiveSuite from "./media/2 Rooms/MasterExecutiveSuite";
import PenthouseSuite from "./media/2 Rooms/PenthouseSuite";

import QueenSuite from "./media/3 Rooms/QueenSuite";
import PremiumQueenSuite from "./media/3 Rooms/PremiumQueenSuite";
import KingSuite from "./media/3 Rooms/KingSuite";
import GrandSuite from "./media/3 Rooms/GrandSuite";
import RoyalSuite from "./media/3 Rooms/RoyalSuite";


function App() {

  const { language } = useContext(LangContext);

  //Array Media one bedroom
  let onebedroomsuitesMedia = [LoftSuiteMedia, DeluxeJuniorSuiteview, PremiumJuniorSuiteMedia, PremiumJuniorDoubleSuiteMedia, JuniorSuiteMedia];
  //Array Media two bedroom
  let twobedroomsuitesMedia = [MasterSuite, PremiumMasterSuite, MasterExecutiveSuite, PenthouseSuite];
  //Arrays Media three bedroom
  let threebedroomsuitesMedia = [QueenSuite, PremiumQueenSuite, KingSuite, GrandSuite, RoyalSuite];

  let allSuitesMEdia = [onebedroomsuitesMedia, twobedroomsuitesMedia, threebedroomsuitesMedia]

  return (
      <BrowserRouter>
        <div className="App">
          <Box width={"100%"}>
            <Box 
              display={"grid"} 
              gridTemplateColumns={"auto minmax(360px, 1400px) auto"} 
              justifyItems={"center"}
              marginTop={"65px"}>
              <NavBar language={language}/>
              <WhatsIcon/>
              <Routes>
                <Route
                  path="/"
                  exact
                  element={
                  <PaginaPrincipal language={language} 
                  allSuitesMEdia={allSuitesMEdia}/>
                  }
                />
                <Route
                  path="/1bedroomsuites"
                  exact
                  element={
                  <SuitesView language={language.oneBedroomSuites} suitesmedia={onebedroomsuitesMedia}/>
                  }
                />
                <Route
                  path="/2bedroomsuites"
                  exact
                  element={
                  <SuitesView language={language.twoBedroomSuites} suitesmedia={twobedroomsuitesMedia}/>
                  }
                />
                <Route
                  path="/3bedroomsuites"
                  exact
                  element={
                  <SuitesView language={language.threeBedroomSuites} suitesmedia={threebedroomsuitesMedia}/>
                  }
                />

                //Rutas para cada una de las suites
                //Suites 1 habitacion
                <Route
                  path="/loftsuite" exact element={
                  <FullInfoSuitesView language={language.oneBedroomSuites.suites[0]} suitesmedia={LoftSuiteMedia} />
                  } />
                <Route
                  path="/juniorsuite" exact element={
                  <FullInfoSuitesView language={language.oneBedroomSuites.suites[4]} suitesmedia={JuniorSuiteMedia}/>
                  } />
                <Route
                  path="/premiumjuniorsuite" exact element={
                  <FullInfoSuitesView language={language.oneBedroomSuites.suites[2]} suitesmedia={PremiumJuniorSuiteMedia}/>
                  } />
                <Route
                  path="/premiumdoublejuniorsuite" exact element={
                  <FullInfoSuitesView language={language.oneBedroomSuites.suites[3]} suitesmedia={PremiumJuniorDoubleSuiteMedia}/>
                  } />
                <Route
                  path="/deluxejuniorsuite" exact element={
                  <FullInfoSuitesView language={language.oneBedroomSuites.suites[1]} suitesmedia={DeluxeJuniorSuiteMedia}/>
                  } />

                //Suites 2 habitaciones
                <Route
                  path="/mastersuite" exact element={
                  <FullInfoSuitesView language={language.twoBedroomSuites.suites[0]} suitesmedia={MasterSuite}/>
                  } />
                <Route
                  path="/premiummastersuite" exact element={
                  <FullInfoSuitesView language={language.twoBedroomSuites.suites[1]} suitesmedia={PremiumMasterSuite}/>
                  } />
                <Route
                  path="/masterexecutivesuite" exact element={
                  <FullInfoSuitesView language={language.twoBedroomSuites.suites[2]} suitesmedia={MasterExecutiveSuite}/>
                  } />
                <Route
                  path="/penthousesuite" exact element={
                  <FullInfoSuitesView language={language.twoBedroomSuites.suites[3]} suitesmedia={PenthouseSuite}/>
                  } />
                
                //Suites 3 habitaciones
                <Route
                  path="/queensuite" exact element={
                  <FullInfoSuitesView language={language.threeBedroomSuites.suites[0]} suitesmedia={QueenSuite}/>
                  } />
                <Route
                  path="/premiumqueensuite" exact element={
                  <FullInfoSuitesView language={language.threeBedroomSuites.suites[1]} suitesmedia={PremiumQueenSuite}/>
                  } />
                <Route
                  path="/kingsuite" exact element={
                  <FullInfoSuitesView language={language.threeBedroomSuites.suites[2]} suitesmedia={KingSuite}/>
                  } />
                <Route
                  path="/grandsuite" exact element={
                  <FullInfoSuitesView language={language.threeBedroomSuites.suites[3]} suitesmedia={GrandSuite}/>
                  } />
                <Route
                  path="/royalsuite" exact element={
                  <FullInfoSuitesView language={language.threeBedroomSuites.suites[4]} suitesmedia={RoyalSuite}/>
                  } />
                  //Otras secciones del sitio
                <Route
                  path="/location" exact element={
                  <Location languaje={language.locations}/>
                  } />
                
                <Route
                  path="/amenities" exact element={
                  <Amenities languaje={language.amenities}/>
                  } />

              </Routes>
              <Footer language={language}/>
            </Box>
          </Box>
        </div>
      </BrowserRouter>
  );
}

export default App;

