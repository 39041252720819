import React, {useContext} from "react";
import { useState, useEffect } from "react";

import { Box, Typography, IconButton, Menu, MenuItem} from "@mui/material";

// Importación de imagenes
import menuIcon from "../assets/NavBar/menu-burger.webp";
import logo from "../assets/NavBar/logo-mini-1.webp"
import tripAdvisor from "../assets/NavBar/best-choices.webp"

import LanguageSwitcher from "./LanguageSwitcher";

// Importación de estilos
import "./NavBar.css"


export default function NavBar({language}){
    
    // Logica de boton de navegacion

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const [widthElement, setWidthElement] = useState(null);
    useEffect(() => {
        const boxElement = document.getElementById("box");
        if (boxElement) {
        setWidthElement(boxElement.offsetWidth);
        }
    }, []);



    // Color de fondo de NavBar
    let bgcolor="#244971";

    // Color de texto
    let textColor="white";
    let fontSize="1.2rem";

    let font ="Century Gothic Italic";

    return(
        <>
        <Box 
        position={"fixed"}
        zIndex={"1000"}
        top={"0"}
        left={"0"}
        width={"100%"}
        display={"grid"} 
        gridTemplateColumns={"auto minmax(300px, 1400px) auto"} 
        justifyItems={"center"} 
        sx={{gridColumnStart:1, gridColumnEnd:4}}>

        
            <Box className="hidebar" height={"65px"} width={"100%"} bgcolor={bgcolor} sx={{gridColumnStart:1, gridColumnEnd:2}}></Box>
            <Box height={"65px"} width={"100%"} bgcolor={bgcolor} className={"navcontainer"} sx={{gridColumnStart:2, gridColumnEnd:3}}>
                
                {/* Inicio de elementos en Navbar */}

                {/* Logo de hotel y trip advisor */}
                <Box className="navlogo">
                    <a href={language.navBar.logoLink}>
                        <img className="navimg" src={logo} alt="European logo"/>
                    </a>
                </Box>
                <Box className="tripAdvisor">
                    <a href={language.navBar.tripadLink}>
                        <img className="navimg" src={tripAdvisor} alt="Trip advisor logo"/>
                    </a>
                </Box>
                
                {/* Inicio de elementos de menu */}
                <Box className="navtext1 navstyled-btn">
                    <a href={language.navBar.menuLink1}>
                        <Typography color={textColor} fontSize={fontSize} fontFamily={font}>
                            {language.navBar.menutext1}
                        </Typography>
                    </a>    
                </Box>

                <Box className="navtext2 navstyled-btn">
                    <a href={language.navBar.menuLink2}>
                        <Typography color={textColor} fontSize={fontSize} fontFamily={font}>
                            {language.navBar.menutext2}
                        </Typography>
                    </a> 
                </Box>

                <Box className="navtext3 navstyled-btn">
                <a href={language.navBar.menuLink3}>
                        <Typography color={textColor} fontSize={fontSize} fontFamily={font}>
                            {language.navBar.menutext3}
                        </Typography>
                    </a>
                </Box>

                <Box className="navtext4 navstyled-btn">
                <a href={language.navBar.menuLink4}>
                        <Typography color={textColor} fontSize={fontSize} fontFamily={font}>
                            {language.navBar.menutext4}
                        </Typography>
                    </a>
                </Box>
                
                
                <button className="navbtn">
                    <a href={language.navBar.makeaReservationLink}>
                        <Typography color={textColor} fontSize={fontSize} fontFamily={font} width={"100%"}>
                            {language.navBar.menubutton}
                        </Typography>
                    </a>
                </button>

                <LanguageSwitcher/>
                

                <Box className="navbarMenu">
                    <IconButton
                        id="basic-button"
                        aria-controls={open ? "basic-menu" : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? "true" : undefined}
                        onClick={handleClick}
                    >
                        <img className="menuicon" src={menuIcon} alt="Menu burger icon"/>
                    </IconButton>
                    
                    {/* Menú desplegable */}
                    <Menu
                        id="basic-menu"
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose}
                        MenuListProps={{
                        "aria-labelledby": "basic-button",
                        }}
                    >
                        <MenuItem onClick={() => { window.location.href =  language.navBar.menuLink1 }}>
                            {language.navBar.menutext1}
                        </MenuItem>
                        <MenuItem onClick={() => { window.location.href = language.navBar.menuLink2 }}>
                            {language.navBar.menutext2}
                        </MenuItem>
                        <MenuItem onClick={() => { window.location.href = language.navBar.menuLink3 }}>
                            {language.navBar.menutext3}
                        </MenuItem>            
                        <MenuItem onClick={() => { window.location.href = language.navBar.menuLink4 }}>
                            {language.navBar.menutext4}
                        </MenuItem> 
                    </Menu>
                </Box>
                    
            </Box>
            <Box className="hidebar" height={"65px"} width={"100%"} bgcolor={bgcolor} sx={{gridColumnStart:3, gridColumnEnd:4}}></Box>
            </Box>
        </>

    )
   
}
