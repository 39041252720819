
import { Box, Typography } from "@mui/material"

import { IntlProvider, FormattedMessage } from "react-intl";
import langEn from "../lang/en-US";
import langEs from "../lang/es-MX.json";

// Importación de imagenes
import phone from "../assets/Footer/phone-call.webp";
import mail from "../assets/Footer/email.webp";
import facebook from "../assets/Footer/facebook.webp";
import instagram from "../assets/Footer/instagram-logo.webp";

// Importación de estilos
import "./Footer.css"

export default function Footer({language}){

    {/* Color de fondo de footer */}
    let bgcolor = "#202020"

    return(
        <>
            <Box className="hidebar" height={"auto"} width={"100%"} bgcolor={bgcolor} sx={{gridColumnStart:1, gridColumnEnd:2}}></Box>
            <Box height={"auto"} width={"100%"} bgcolor={bgcolor} className={"footcontainer"} sx={{gridColumnStart:2, gridColumnEnd:3}}
                paddingTop={15} paddingBottom={20}>

                {/* Inicio de elementos de texto en footer */}

                {/* Nombre hotel */}
                <Box className="name" >
                    <Typography fontFamily={"Century Gothic"} 
                        textAlign={"center"} color={"white"}
                        fontSize={"1.5rem"}>
                        {language.footer.footertext1}
                    </Typography>

                    <Typography fontFamily={"Century Gothic"} 
                        textAlign={"center"} color={"white"}
                        fontSize={"1.2rem"}>
                        {language.footer.footertext2}
                    </Typography>
                </Box>

                {/* Telefono */}
                <Box className="tel">
                    <a href={language.footer.footerPhoneLink} className="center">
                        <img className="icons" src={phone} alt="phone icon" loading="lazy" />
                        <Typography fontFamily={"Century Gothic"} 
                            textAlign={"right"} color={"white"}
                            fontSize={"1.2rem"}>
                            {language.footer.footerPhone}
                        </Typography>
                    </a>    
                </Box>

                {/* Envio de correo */}
                <Box className="mail">
                    <a href={language.footer.footerMailLink} className="center">
                        <img className="icons" src={mail} alt="mail icon" loading="lazy"/>
                        <Typography fontFamily={"Century Gothic"} 
                            textAlign={"left"} color={"white"}
                            fontSize={"1.2rem"}>
                            {language.footer.footerMail}
                        </Typography>
                    </a>
                </Box>

                {/* Facebook */}
                <Box className="facebook ">
                    <a href={language.footer.footerFacebookLink} className="center">
                        <img className="icons" src={facebook} alt="Facebook icon" loading="lazy" />
                        <Typography fontFamily={"Century Gothic"} 
                            textAlign={"right"} color={"white"}
                            fontSize={"1.2rem"}>
                            {language.footer.footerFacebook}
                        </Typography>
                    </a>
                </Box>

                {/* Instagram */}
                <Box className="instagram">
                <a href={language.footer.footerInstagramLink} className="center">
                    <img className="icons" src={instagram} alt="Instagram icon" loading="lazy" />
                    <Typography fontFamily={"Century Gothic"} 
                        textAlign={"left"} color={"white"}
                        fontSize={"1.2rem"}>
                        {language.footer.footerInstagram}
                    </Typography>
                </a>
                </Box>
            </Box>
            <Box className="hidebar" height={"auto"} width={"100%"} bgcolor={bgcolor} sx={{gridColumnStart:3, gridColumnEnd:4}}></Box>
        </>
    )
}