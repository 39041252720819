import { Box, Typography } from "@mui/material";
import React, { useState, useRef } from 'react';


import ReactImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";

import "../Amenities/Amenities.css"

// Imagenes de servicios
import AmenitiesMedia from "../media/AmenitiesMedia";

// Iconos servicios adicionales
import wifi from "../assets/amenities/Icons/wi-fi.webp";
import breakfast from "../assets/amenities/Icons/breakfast.webp";
import coffee from "../assets/amenities/Icons/cup.webp";
import landury from "../assets/amenities/Icons/laundry.webp";
import pet from "../assets/amenities/Icons/pet-care.webp";
import security from "../assets/amenities/Icons/security-agent.webp";

export default function Amenities({languaje}){
    const data = {
        addAmenitiesIcons:[
            {text:languaje.addamenitie1,
            icon: wifi
            },
            {text:languaje.addamenitie2,
            icon:breakfast
            },
            {text:languaje.addamenitie3,
            icon:coffee
            },
            {text:languaje.addamenitie4,
            icon:landury
            },
            {text:languaje.addamenitie5,
            icon:pet
            },
            {text:languaje.addamenitie6,
            icon:security
            }
        ],
    }

    const [currentIndex, setCurrentIndex] = useState(0); // Estado para almacenar el índice actual

    const handleSlide = index => {
        setCurrentIndex(index);
      };

    return(
        <Box width={"100%"} className="flex" flexDirection={"column"} sx={{gridColumnStart:2, gridColumnEnd:3}}>
            
            <Typography fontSize={"2rem"} fontFamily={"Century Gothic"} my={2} textAlign="center">{languaje.tittle}</Typography>
            <Typography fontSize={"1rem"} width={"85%"} textAlign={"justify"}>{languaje.text1}</Typography>
            <Typography fontSize={"2rem"} fontFamily={"Century Gothic"} mt={5} textAlign={"center"}>{languaje.tittle2}</Typography>

            <Box bgcolor={"#244971"} py={2} maxWidth={"900px"} width={"95%"} display={"grid"} gridTemplateColumns={"100%"} alignContent={"center"} justifyItems={"center"} position={"relative"}>
                <Box width={"96%"} >
                    <ReactImageGallery
                        lazyLoad
                        items={AmenitiesMedia.images}
                        showBullets
                        autoPlay={true}
                        showFullscreenButton={false}
                        onSlide={index => handleSlide(index)}
                    />
                </Box>
            <   Typography className="amenitiesText" fontFamily={"Century Gothic"} color={"#fff"} my={1}
                    position={"absolute"}>{languaje.amenities[currentIndex]}</Typography>
            </Box>

            <Typography fontSize={"2rem"} fontFamily={"Century Gothic"} my={5} textAlign={"center"}>{languaje.tittle3}</Typography>

            <Box display={"grid"} gridTemplateColumns={"1fr 1fr"}>
                {data.addAmenitiesIcons.map((amenitie, index) => (
                    <Box justifyContent={"center"} display={"flex"} flexWrap={"wrap"} mb={3}>
                        <img src={amenitie.icon} className="amenitieimg" key={index} alt="amenitie icon"  loading="lazy"/>
                        <Typography fontSize={"1rem"} textAlign={"center"} width={"100%"}>{amenitie.text}</Typography>
                    </Box>
                ))}
            </Box>
        </Box>
    )
}