import React, {createContext, useState, useEffect } from "react";

import enUS from "../lang/en-US.json";
import esMX from "../lang/es-MX.json";


const LangContext = createContext();

const broswerLanguage = () => {

  if (navigator.language.startsWith("es")) {
    return esMX;
  }
  else 
    return enUS;
  

}

export const LangProvider = ({ children }) => {
    const [language, setLanguage] = useState(() => {
      const savedLanguage = localStorage.getItem("language");
      return savedLanguage ? JSON.parse(savedLanguage) : broswerLanguage();
    });
  
    useEffect(() => {
      localStorage.setItem("language", JSON.stringify(language));
    }, [language]);
  
    return(
        <LangContext.Provider value={{language, setLanguage}}>
            {children}
        </LangContext.Provider>
    )
}

export default LangContext;