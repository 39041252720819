//Suites 3 recamaras

// Premium Queen Suite
import image1 from "../../assets/Suites/Suites 3 Recamaras/PremiumQueenSuite/PremiumQueenSuite-1.webp"
import image2 from "../../assets/Suites/Suites 3 Recamaras/PremiumQueenSuite/PremiumQueenSuite-2.webp"
import image3 from "../../assets/Suites/Suites 3 Recamaras/PremiumQueenSuite/PremiumQueenSuite-3.webp"
import image4 from "../../assets/Suites/Suites 3 Recamaras/PremiumQueenSuite/PremiumQueenSuite-4.webp"
import image5 from "../../assets/Suites/Suites 3 Recamaras/PremiumQueenSuite/PremiumQueenSuite-5.webp"
import image6 from "../../assets/Suites/Suites 3 Recamaras/PremiumQueenSuite/PremiumQueenSuite-6.webp"
import image7 from "../../assets/Suites/Suites 3 Recamaras/PremiumQueenSuite/PremiumQueenSuite-7.webp"
import image8 from "../../assets/Suites/Suites 3 Recamaras/PremiumQueenSuite/PremiumQueenSuite-8.webp"
import image9 from "../../assets/Suites/Suites 3 Recamaras/PremiumQueenSuite/PremiumQueenSuite-9.webp"
import image10 from "../../assets/Suites/Suites 3 Recamaras/PremiumQueenSuite/PremiumQueenSuite-10.webp"
import image11 from "../../assets/Suites/Suites 3 Recamaras/PremiumQueenSuite/PremiumQueenSuite-11.webp"
import image12 from "../../assets/Suites/Suites 3 Recamaras/PremiumQueenSuite/PremiumQueenSuite-12.webp"

const PremiumQueenSuite = {
    "images":[
        {
        "original":image1,
        "thumbnail":image1
        },
        {
        "original":image2,
        "thumbnail":image2
        },
        {
        "original":image3,
        "thumbnail":image3
        },
        {
        "original":image4,
        "thumbnail":image4
        },
        {
        "original":image5,
        "thumbnail":image5
        },
        {
        "original":image6,
        "thumbnail":image6
        },
        {
        "original":image7,
        "thumbnail":image7
        },
        {
        "original":image8,
        "thumbnail":image8
        },
        {
        "original":image9,
        "thumbnail":image9
        },
        {
        "original":image10,
        "thumbnail":image10
        },
        {
        "original":image11,
        "thumbnail":image11
        },
        {
        "original":image12,
        "thumbnail":image12
        }
    ] 
}

export default PremiumQueenSuite;