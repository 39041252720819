import { Box } from "@mui/material";

import ExploreOurSuitesSection from "./ExploreOurSuitesSection";

import "./ExploreOurSuites.css";




export default function ExploreOurSuites({language, allSuitesMEdia}){
    
    // Selección de fotos por tipo de Suites
    // Fotos Suites 1 recamara
    const Suites1room = [
        allSuitesMEdia[0][0].images[1].original,
        allSuitesMEdia[0][1].images[2].original,
        allSuitesMEdia[0][1].images[4].original,
        allSuitesMEdia[0][1].images[3].original,
        allSuitesMEdia[0][1].images[5].original,
    ]
    const Suites2room = [
        allSuitesMEdia[1][1].images[1].original,
        allSuitesMEdia[1][1].images[3].original,
        allSuitesMEdia[1][1].images[4].original,
        allSuitesMEdia[1][1].images[6].original,
        allSuitesMEdia[1][1].images[7].original,
    ]
    const Suites3room = [
        allSuitesMEdia[2][4].images[1].original,
        allSuitesMEdia[2][4].images[5].original,
        allSuitesMEdia[2][4].images[6].original,
        allSuitesMEdia[2][4].images[7].original,
        allSuitesMEdia[2][4].images[8].original,
    ]
    
    return(
        <Box className="containerMainRoom" >
            <ExploreOurSuitesSection suitelang={language.mainSection.oneBedroomSuites} media={Suites1room}/>
            <ExploreOurSuitesSection suitelang={language.mainSection.twoBedroomSuites} media={Suites2room}/>
            <ExploreOurSuitesSection suitelang={language.mainSection.threeBedroomSuites} media={Suites3room}/>
        </Box>
    )
}