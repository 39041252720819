import { Box, Paper, Typography } from "@mui/material";

import "./ExploreOurSuitesSection.css"


export default function ExploreOurSuitesSection({suitelang, media}){

    {/* Color de texto */}
    let textColor="#444444";

    return(
        <a href={suitelang.textbuttonlink}>
            <Paper className="containerExploreOurSuitesSection" elevation={2} >
                    <Box className="principal">
                        <img className="imgMainRooms" src={media[0]} alt="Suite photo 1" loading="eager"/>
                    </Box>
                    <Box className="foto1">
                        <img className="imgMainRooms" src={media[1]} alt="Suite photo 2" loading="lazy"/>
                    </Box>
                    <Box className="foto2">
                        <img className="imgMainRooms" src={media[2]} alt="Suite photo 3" loading="lazy"/>
                    </Box>
                    <Box className="foto3">
                        <img className="imgMainRooms" src={media[3]} alt="Suite photo 4" loading="lazy"/>
                    </Box>

                    {
                    media[4] && (
                        <Box className="foto4">
                        <img className="imgMainRooms" src={media[4]} alt="Suite photo 5" loading="lazy"/>
                        </Box>)
                    }
                    
                    <Box className="Exploretittle">
                        <Typography textAlign={"center"} fontSize={"1.5rem"} fontFamily={"Century Gothic"}>
                            {suitelang.tittle}
                        </Typography>
                    </Box>
                    <Box className="textos">    
                        <Typography  fontSize={"1rem"} fontFamily={"Century Gothic"} color={textColor}>
                            {suitelang.text1}
                        </Typography>
                        <Typography  fontSize={"1rem"} fontFamily={"Century Gothic"} color={textColor}>
                            {suitelang.text2}
                        </Typography>
                        <Typography fontSize={"1rem"} fontFamily={"Century Gothic"} color={textColor}>
                            {suitelang.text3}
                        </Typography>
                        <Typography  fontSize={"1rem"} fontFamily={"Century Gothic"} color={textColor}>
                            {suitelang.text4}
                        </Typography>
                    </Box>
                    <Box className="Explorebtncontainer" display={"flex"} justifyContent={"center"} pt={"1rem"}>
                        
                            <button className="Mainbtn"  >
                                <Typography color={"white"} fontSize={"1rem"} fontFamily={"Century Gothic"}>
                                    {suitelang.textbutton}
                                </Typography>
                            </button>
                            
                            
                    </Box>
                
            </Paper>
        </a>
    )
   
}
