import { Box, Paper, Typography } from "@mui/material";

import ReactImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";

//Importacion de iconos de caracteristicas
import ruler from "../assets/icons/ruler-black.webp";
import bed from "../assets/icons/bed-black.webp";
import sink from "../assets/icons/sink-black.webp";

//Importación de estilos
import "./SuitesViewSection.css"

export default function SuitesViewSection({language, media}){

    {/* Color de texto */}
    let textColor="#444444";

    {/* Fuente de caracteristicas */}
    let font ="Century Gothic Italic"


    return(
            <Paper className="containerSuitesViewSection" elevation={2} >

            <Box className="imgGallery"  alignSelf={"center"}
                    width={"100%"}>
                  <ReactImageGallery
                    items={media.images}
                    showBullets
                    showFullscreenButton={true}
                    showNav={false}
                    thumbnailPosition={"bottom"}
                  /> 
              </Box>

                <Box className="principalSuitesViewSection">
                    <img className="imgSuitesViewSection" src={media.images[0].original} alt="suite photo 1"/>
                </Box>
                <Box className="foto1SuitesViewSection">
                    <img className="imgSuitesViewSection" src={media.images[1].original} alt="suite photo 2"/>
                </Box>
                <Box className="foto2SuitesViewSection">
                    <img className="imgSuitesViewSection" src={media.images[2].original} alt="suite photo 3"/>
                </Box>
                <Box className="foto3SuitesViewSection">
                    <img className="imgSuitesViewSection" src={media.images[3].original} alt="suite photo 4"/>
                </Box>
              
                
                <Box className="textosSuitesViewSection">
                    <Typography textAlign={"left"} fontSize={"1.5rem"} fontFamily={"Century Gothic"}>
                        {language.tittle}
                    </Typography>
                    <Typography textAlign={"left"} fontSize={"1rem"} fontFamily={"Century Gothic"} color={textColor}>
                        {language.text1}
                    </Typography>
                </Box>

                <Box className="char1SuitesViewSection flex">
                    <img className="imgChar" src={ruler} alt="ruler" />
                    <Typography textAlign={"left"} fontSize={".8rem"} fontFamily={font}>
                        {language.char1}
                    </Typography>
                </Box>
                <Box className="char2SuitesViewSection flex">
                    <img className="imgChar" src={bed} alt="bed" />
                    <Typography textAlign={"left"} fontSize={".8rem"} fontFamily={font}>
                        {language.char2}
                    </Typography>
                </Box>
                {
                    language.char25 && 
                    <Box className="char3SuitesViewSection flex">
                        <img className="imgChar" src={bed} alt="bed2" loading="lazy" />
                        <Typography textAlign={"left"} fontSize={".8rem"} fontFamily={font}>
                            {language.char25}
                        </Typography>
                    </Box>
                }
                {
                    language.char26 && 
                    <Box className="char4SuitesViewSection flex">
                        <img className="imgChar" src={bed} alt="bed3" loading="lazy" />
                        <Typography textAlign={"left"} fontSize={".8rem"} fontFamily={font}>
                            {language.char26}
                        </Typography>
                    </Box>
                }

                <Box className="char5SuitesViewSection flex">
                    <img className="imgChar" src={sink} />
                    <Typography textAlign={"left"} fontSize={".8rem"} fontFamily={font}>
                        {language.char3}
                    </Typography>
                </Box>

                <Box className="Mainbtncontainer" display={"flex"} justifyContent={"space-around"} pt={"1rem"}>
                    <a href={language.textbutton2Link}>
                        <button className="Mainbtn"  >
                            <Typography color={"white"} fontSize={"1rem"} fontFamily={"Century Gothic"}>
                                {language.textbutton2}
                            </Typography>
                        </button>
                    </a>
                    <a href={language.textbuttonlink}>
                        <button className="Mainbtn"  >
                            <Typography color={"white"} fontSize={"1rem"} fontFamily={"Century Gothic"}>
                                {language.textbutton}
                            </Typography>
                        </button>
                    </a>
                </Box>
            </Paper>
    )
   
}
