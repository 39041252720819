import { Box, Typography } from "@mui/material";

import SuitesViewSection from "./SuitesViewSection";


import "./SuitesView.css";

export default function SuitesView ({language, suitesmedia}){
    return(
            <Box sx={{gridColumnStart:2, gridColumnEnd:3}}>
                <Typography textAlign={"center"} fontSize={"1.5rem"} fontFamily={"Century Gothic"} marginY={1}>
                    {language.tittle}
                </Typography> 
                <Box className="containerSuitesView" >
                    {
                        language.suites.map((suitelang, index) => (
                            <SuitesViewSection key={index} language={suitelang} media={suitesmedia[index]} />
                        ))
                    }
                    
                </Box>
            </Box>
    )
}