import { Box, Container, Paper, Typography } from "@mui/material";
import Slider from "./Slider";
import ExploreOurSuites from "./ExploreOurSuites";

import ReservationPcker from "./ReservationPcker";

function PaginaPrincipal({language, allSuitesMEdia}){

    return(
            <Box width={"100%"} sx={{gridColumnStart:2, gridColumnEnd:3}}> 

                <Slider/>
                <ReservationPcker language={language}/>
                    <Typography textAlign={"center"} fontSize={"2rem"} fontFamily={"Century Gothic"}>
                        {language.mainSection.tittle}
                    </Typography>
                    <ExploreOurSuites language={language} allSuitesMEdia={allSuitesMEdia}/>

            </Box>
    )
   
}

export default PaginaPrincipal;