import Button from '@mui/material/Button';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';

function WhatsIcon(){
    return(

        <Button 
        aria-label='Enlace a chat whatsapp'
        variant='contained' color='inherit'
        href='https://wa.me/523314501012'
        sx={{
            background:"white",
            borderRadius:100,
            boxShadow: '2px 2px 4px 2px rgba(0, 0, 0, 0.2)',
            position: 'fixed',
            bottom: 50,
            right: 16,
            zIndex:100,
            color:"green",
            '&:active, &:focus': {
                background: "white", // Mantiene el fondo blanco al hacer clic
              },
        }}
        >
            <WhatsAppIcon fontSize='large'
            sx={{
                height:50
            }}/>
        </Button>

    );
}
export default WhatsIcon;