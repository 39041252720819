import React, { useState, useEffect } from "react";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import {
  Box,
  Typography,
  Button,
  useMediaQuery,
  Paper,
  MenuItem,
  InputLabel,
  FormControl,
} from "@mui/material";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import Select from "@mui/material/Select";
import dayjs from "dayjs";

export default function ReservationPcker({language}) {
  const isSmallScreen_XS = useMediaQuery("(max-width:600px)");

  const [checkInDate, setCheckInDate] = useState(dayjs());
  const [checkOutDate, setCheckOutDate] = useState(dayjs().add(1, "day"));
  const [minDate, setMinDate] = useState(dayjs().add(1, "day"));

  const handleMinDate = (date) => {
    setCheckInDate(date);
    setMinDate(date.add(1, "day"));
  };

  const handleCheckOutDate = (date) => {
    setCheckOutDate(date);
  };

  useEffect(() => {
    if (checkInDate.isAfter(checkOutDate) || checkInDate.isSame(checkOutDate)) {
      setCheckOutDate(checkInDate.add(1, "day"));
    }
  }, [checkInDate, checkOutDate]);

  const [adults, setAdults] = React.useState(1);
  const handleAdultChange = (event) => {
    setAdults(event.target.value);
  };

  const [childs, setChilds] = React.useState(0);
  const handleChildChange = (event) => {
    setChilds(event.target.value);
  };

  
  

  const getURl = () => {
    let baseUrl =
      "https://booking.roomcloud.net/be/se2/hotel.jsp?curr=MXN&lang=es&shop=&hotel=11461";
    let checkIn = "&checkin=" + dayjs(checkInDate).format("YYYYMMDD");
    let checkOut = "&checkout=" + dayjs(checkOutDate).format("YYYYMMDD");
    let numberOfAdults = "&adults=" + adults;
    let numberOfChilds = "&children=" + childs;
    let childsAge1 = (childsAge[0] > 0)?("&ages_0=" + childsAge[0]):"";
    let childsAge2 = (childsAge[1] > 0)?("&ages_0=" + childsAge[1]):"";
    let childsAge3 = (childsAge[2] > 0)?("&ages_0=" + childsAge[2]):"";
    let childsAge4 = (childsAge[3] > 0)?("&ages_0=" + childsAge[3]):"";
    let childsAge5 = (childsAge[4] > 0)?("&ages_0=" + childsAge[4]):"";
    let childsAge6 = (childsAge[5] > 0)?("&ages_0=" + childsAge[5]):"";

    return baseUrl + checkIn + checkOut + numberOfAdults + numberOfChilds + childsAge1 + childsAge2 + childsAge3 + childsAge4 + childsAge5 + childsAge6;
  };

  const [childsAge, setChildsAge] = useState(Array.from({ length: 17 }, () => 0));

  const handleAgeChange = (index) => (event) => {
    const newValue = event.target.value;
    setChildsAge(prevState => {
      const newState = [...prevState];
      newState[index] = newValue;
      return newState;
    });
  };
  
  const childComponents = Array.from({ length: childs }, (_, index) => (
    <FormControl
      key={index}
      sx={{
        background: "white",
        width: { xs: "160px", sm: "245px" },
        flexBasis: {
          xs: "calc(50% - 20px)",
          sm: "calc(25% - 20px)",
          md: "calc(20% - 20px)",
        },
      }}
    >
      <InputLabel id={`lbl-ninos-${index}`}>{language.datePicker.kidsage} {index + 1}</InputLabel>
      <Select
        aria-label={`edad de menores ${index}`}
        id={index}
        value={childsAge[index]}
        label={`Niños ${index}`}
        onChange={handleAgeChange(index)}
      >
        {Array.from({ length: 18 }, (_, value) => (
          <MenuItem key={value} value={value}>
            {value}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  ));

  return (
    <Box mb={"20px"}>
    <LocalizationProvider dateAdapter={AdapterDayjs} >
      <Box
        display={"flex"}
        justifyContent={"center"}
        sx={{ width: "100%"}}
      >
        <Paper
          elevation={0}
          sx={{
            background: "rgba(255, 255, 255, .95)",
            width: "1188px",
            display: "flex",
            flexWrap: "wrap",
            alignContent: "stretch",
            gap: 2,
            justifyContent: "center",
            alignItems: "center",
            my: 2,
          }}
        >
          <DatePicker
            label="Check-in"
            disablePast
            value={checkInDate}
            onChange={handleMinDate}
            sx={{
              background: "white",
              borderRadius: "12px",
              width: { xs: "160px", sm: "auto" },
              flexBasis: {
                xs: "calc(50% - 20px)",
                sm: "calc(25% - 20px)",
                md: "calc(20% - 20px)",
              },
            }}
          />

          <DatePicker
            label="Check-out"
            value={checkOutDate}
            onChange={handleCheckOutDate}
            minDate={minDate}
            sx={{
              background: "white",
              borderRadius: "12px",
              width: { xs: "160px", sm: "auto" },
              flexBasis: {
                xs: "calc(50% - 20px)",
                sm: "calc(25% - 20px)",
                md: "calc(20% - 20px)",
              },
            }}
          />

          <FormControl
            sx={{
              background: "white",
              width: { xs: "160px", sm: "245px" },
              flexBasis: {
                xs: "calc(50% - 20px)",
                sm: "calc(25% - 20px)",
                md: "calc(20% - 20px)",
              },
            }}
          >
            <InputLabel id="lbl-adultos">{language.datePicker.adults}</InputLabel>

            <Select
              aria-label="numero de adultos"
              id="adultos"
              value={adults}
              label="Adultos"
              onChange={handleAdultChange}
            >
              <MenuItem value={1}>1</MenuItem>
              <MenuItem value={2}>2</MenuItem>
              <MenuItem value={3}>3</MenuItem>
              <MenuItem value={4}>4</MenuItem>
              <MenuItem value={5}>5</MenuItem>
              <MenuItem value={6}>6</MenuItem>
            </Select>
          </FormControl>

          <FormControl
            sx={{
              background: "white",
              width: { xs: "160px", sm: "245px" },
              flexBasis: {
                xs: "calc(50% - 20px)",
                sm: "calc(25% - 20px)",
                md: "calc(20% - 20px)",
              },
            }}
          >
            <InputLabel id="lbl-ninos">{language.datePicker.kids}</InputLabel>

            <Select
              aria-label="numero de menores"
              id="select menores"
              value={childs}
              label="Niños"
              onChange={handleChildChange}
            >
              <MenuItem value={0}>0</MenuItem>
              <MenuItem value={1}>1</MenuItem>
              <MenuItem value={2}>2</MenuItem>
              <MenuItem value={3}>3</MenuItem>
              <MenuItem value={4}>4</MenuItem>
              <MenuItem value={5}>5</MenuItem>
              <MenuItem value={6}>6</MenuItem>
            </Select>
            
          </FormControl>

            {childComponents}

          <Button
            variant="outlined"
            href={getURl()}
            sx={{
              height: { xs: 50, sm: 55 },
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              color:"#FFF",
              bgcolor:"#244971",
              borderColor:"#244971",'&:hover': {
              borderColor:"#FFF", bgcolor:"#032034",color:"white"},
              flexBasis: {
                xs: "calc(50% - 20px)",
                sm: "calc(25% - 20px)",
                md: "calc(20% - 20px)",
              }
            }}
          >
           
            <Typography
              variant={isSmallScreen_XS ? "caption" : "body2"}
              sx={{ pr: 1, textAlign: "center", fontWeight:"bold" }}
            >
              {language.datePicker.button}
            </Typography>
            <CalendarMonthIcon />
          </Button>
        </Paper>
      </Box>
    </LocalizationProvider>
    </Box>
  );
}
